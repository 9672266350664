import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

const Document = styled.div`
  margin: 20px;
  padding: 20px;
  border-radius: 6px;
  white-space: pre-wrap;
  background: white;
`

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Document>
      <div>
        <h1>{frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <a styleName="footer" href="/">
        Agree & Close
      </a>
    </Document>
  )
}
export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`
